#main-about {
  width: 100vw;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#main-about .this-div-about-page {
  width: 70vw;
  height: 70vh;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.097);
  color: white;
  font-size: 3vh;
  overflow: scroll;
  z-index: 1;
}
#main-about .this-div-about-otherside {
  width: 20vw;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
#main-about .this-div-about-otherside .reactlogo {
  height: 50vh;
  animation: rotinglogo 5s ease infinite;
  opacity: 0.5;
}
@keyframes rotinglogo {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(360deg);
    transform: scale(1.3);
  }
  100% {
    transform: rotate(0deg);
    transform: 1;
  }
}/*# sourceMappingURL=style.css.map */