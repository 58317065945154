@font-face {
  font-family: "Nastaliq";
  src: url(../../files/font/Shekasteh.ttf);
}
#main-writing {
  min-height: 80vh;
  width: 100vw;
  text-align: center;
}
#main-writing .p {
  font-family: "Nastaliq";
  font-size: 2vw;
  color: white;
}
#main-writing .p-english {
  font-size: 2vw;
  color: white;
}
#main-writing .pp {
  font-size: 1.5vw;
  color: white;
}
#main-writing .stronger {
  color: purple;
  font-size: 1.5vw;
}

#audio-background {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
}
#audio-background p {
  font-family: "Nastaliq";
  font-size: larger;
  color: white;
}

.audio-back {
  width: 100vw;
  height: 5vh;
  border-radius: 0;
  margin: 20px 0;
}

.audio-back::-webkit-media-controls-enclosure {
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0);
}

.h3-ifInterested {
  font-size: 2vw;
  color: rgba(255, 255, 255, 0.315);
  margin: 200px 0;
}
.h3-ifInterested strong {
  color: blue;
  text-decoration: underline;
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all;
}/*# sourceMappingURL=style.css.map */