@font-face {
    font-family: 'Nastaliq';
    src: url(../../files/font/Shekasteh.ttf) ;
}
#main-writing{
    min-height: 80vh;
    width: 100vw;
    text-align: center;
    .p{
        font-family: 'Nastaliq';
        font-size: 2vw;
        color: white;
    }
    .p-english{
        font-size: 2vw;
        color: white;
    }
    .pp{
        font-size:1.5vw;
        color: white;
    }
    .stronger{
        color: purple;
        font-size: 1.5vw;
    }
}
#audio-background{
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;
    p{
        font-family: 'Nastaliq';
        font-size: larger;
        color: white;
    }
}
.audio-back{
    width: 100vw;
    height: 5vh;
    border-radius: 0;
    margin: 20px 0;
}
.audio-back::-webkit-media-controls-enclosure {
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0);
}
.h3-ifInterested{
    font-size: 2vw;
    color: rgba(255, 255, 255, 0.315);
    margin: 200px 0;
    strong{
        color: blue;
        text-decoration: underline;
        user-select: all;
    }
}