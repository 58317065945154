#main-paints {
  min-height: 80vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
#main-paints img {
  width: 300px;
  margin: 20px;
}
#main-paints div {
  width: 300px;
  height: auto;
  margin: 20px;
  text-align: center;
  color: white;
}
#main-paints div p {
  margin-bottom: 50px;
}
#main-paints div .price-paints {
  color: black;
  text-decoration: none;
  background-color: white;
  border: 1px black solid;
  padding: 10px;
  border-radius: 50px;
  margin-top: 100px;
}
#main-paints div:last-child {
  margin-bottom: 100px;
}/*# sourceMappingURL=style.css.map */